import ar from "../locales/ar.json";
import bg from "../locales/bg.json";
import bn from "../locales/bn.json";
import ca from "../locales/ca.json";
import common_ar from "../locales/common/ar_SA.json";
import common_bg from "../locales/common/bg_BG.json";
import common_bn from "../locales/common/bn_IN.json";
import common_ca from "../locales/common/ca_ES.json";
import common_cs from "../locales/common/cs_CZ.json";
import common_da from "../locales/common/da_DK.json";
import common_de from "../locales/common/de_DE.json";
import common_el from "../locales/common/el_GR.json";
import common_en from "../locales/common/en_US.json";
import common_es from "../locales/common/es_ES.json";
import common_et from "../locales/common/et_EE.json";
import common_fi from "../locales/common/fi_FI.json";
import common_fr from "../locales/common/fr_FR.json";
import common_he from "../locales/common/he_IL.json";
import common_hi from "../locales/common/hi_IN.json";
import common_hr from "../locales/common/hr_HR.json";
import common_hu from "../locales/common/hu_HU.json";
import common_it from "../locales/common/it_IT.json";
import common_ja from "../locales/common/ja_JP.json";
import common_ko from "../locales/common/ko_KR.json";
import common_lt from "../locales/common/lt_LT.json";
import common_lv from "../locales/common/lv_LV.json";
import common_nl from "../locales/common/nl_NL.json";
import common_pl from "../locales/common/pl_PL.json";
import common_pt from "../locales/common/pt_PT.json";
import common_ro from "../locales/common/ro_RO.json";
import common_ru from "../locales/common/ru_RU.json";
import common_sk from "../locales/common/sk_SK.json";
import common_sq from "../locales/common/sq_AL.json";
import common_sv from "../locales/common/sv_SE.json";
import common_tr from "../locales/common/tr_TR.json";
import common_uk from "../locales/common/uk_UA.json";
import common_zhcn from "../locales/common/zh_CN.json";
import cs from "../locales/cs.json";
import da from "../locales/da.json";
import de from "../locales/de.json";
import el from "../locales/el.json";
import en from "../locales/en.json";
import es from "../locales/es.json";
import et from "../locales/et.json";
import fi from "../locales/fi.json";
import fr from "../locales/fr.json";
import he from "../locales/he.json";
import hi from "../locales/hi.json";
import hr from "../locales/hr.json";
import hu from "../locales/hu.json";
import it from "../locales/it.json";
import ja from "../locales/ja.json";
import ko from "../locales/ko.json";
import lt from "../locales/lt.json";
import lv from "../locales/lv.json";
import nl from "../locales/nl.json";
import pl from "../locales/pl.json";
import pt from "../locales/pt.json";
import ro from "../locales/ro.json";
import ru from "../locales/ru.json";
import sk from "../locales/sk.json";
import sq from "../locales/sq.json";
import sv from "../locales/sv.json";
import tr from "../locales/tr.json";
import uk from "../locales/uk.json";
import zh from "../locales/zh.json";

export const supportedLanguages = {
  AFRIKAANS_SOUTH_AFRICA: {
    nameTKey: "str_language_afrikaans",
    originalName: "Afrikaans",
    flagName: "Afrikaans",
    apiKey: "af",
    bcp47: "af-ZA",
  },
  ARABIC_SAUDI_ARABIA: {
    nameTKey: "str_language_arabic",
    originalName: "العربية",
    flagName: "Arabic",
    apiKey: "ar",
    bcp47: "ar-XA",
  },
  BENGALI_INDIA: {
    nameTKey: "str_language_bengali",
    originalName: "Bengali",
    flagName: "Bengali",
    apiKey: "bn",
    bcp47: "bn-IN",
  },
  BULGARIAN_BULGARIA: {
    nameTKey: "str_language_bulgarian",
    originalName: "Български",
    flagName: "Bulgarian",
    apiKey: "bg",
    bcp47: "bg-BG",
  },
  CATALAN_SPAIN: {
    nameTKey: "str_language_catalan",
    originalName: "Català",
    flagName: "Catalan",
    apiKey: "ca",
    bcp47: "ca-ES",
  },
  CHINESE_MANDARIN_SIMPLIFIED_HONG_KONG: {
    nameTKey: "str_language_chinese_simplified",
    originalName: "中文(简体字)",
    flagName: "ChineseSimplified",
    apiKey: "zh-CHS",
    bcp47: "zh-Hans-HK",
  },
  CHINESE_MANDARIN_TRADITIONAL_TAIWAN: {
    nameTKey: "str_language_chinese_traditional",
    originalName: "中文(台湾普通话)",
    flagName: "ChineseSimplified",
    apiKey: "zh-CHT",
    bcp47: "zh-Hant-TW",
  },
  CHINESE_CANTONESE_TRADITIONAL_HONG_KONG: {
    nameTKey: "str_language_chinese_cantonese",
    originalName: "中文(粤语)",
    flagName: "ChineseSimplified",
    apiKey: "yue",
    bcp47: "yue-HK",
  },
  CZECH_CZECH_REPUBLIC: {
    nameTKey: "str_language_czech",
    originalName: "Čeština",
    flagName: "Czech",
    apiKey: "cs",
    bcp47: "cs-CZ",
  },
  CROATIAN_CROATIA: {
    nameTKey: "str_language_croatian",
    originalName: "Hrvatski",
    flagName: "Croatian",
    apiKey: "hr",
    bcp47: "hr-HR",
  },
  DANISH_DENMARK: {
    nameTKey: "str_language_danish",
    originalName: "Dansk",
    flagName: "Danish",
    apiKey: "da",
    bcp47: "da-DK",
  },
  DUTCH_NETHERLANDS: {
    nameTKey: "str_language_dutch",
    originalName: "Nederlands",
    flagName: "Dutch",
    apiKey: "nl",
    bcp47: "nl-NL",
  },
  ENGLISH_UNITED_STATES: {
    nameTKey: "str_language_english_us",
    originalName: "English (United States)",
    flagName: "EnglishUSA",
    apiKey: "en",
    bcp47: "en-US",
  },
  ENGLISH_AUSTRALIA: {
    nameTKey: "str_language_english_australia",
    originalName: "English (Australia)",
    flagName: "EnglishAustralia",
    apiKey: "en",
    bcp47: "en-AU",
  },
  ENGLISH_UNITED_KINGDOM: {
    nameTKey: "str_language_english_gb",
    originalName: "English (United Kingdom)",
    flagName: "EnglishUK",
    apiKey: "en",
    bcp47: "en-GB",
  },
  ENGLISH_INDIA: {
    nameTKey: "str_language_english_india",
    originalName: "English (India)",
    flagName: "EnglishIndia",
    apiKey: "en",
    bcp47: "en-IN",
  },
  FILIPINO_PHILIPPINES: {
    nameTKey: "str_language_filipino",
    originalName: "Filipino",
    flagName: "Filipino",
    apiKey: "fil",
    bcp47: "fil-PH",
  },
  FINNISH_FINLAND: {
    nameTKey: "str_language_finnish",
    originalName: "Suomi",
    flagName: "Finnish",
    apiKey: "fi",
    bcp47: "fi-FI",
  },
  FRENCH_FRANCE: {
    nameTKey: "str_language_french_fr",
    originalName: "Français (France)",
    flagName: "FrenchFrance",
    apiKey: "fr",
    bcp47: "fr-FR",
  },
  FRENCH_CANADA: {
    nameTKey: "str_language_french_ca",
    originalName: "French (Canada)",
    flagName: "FrenchCanada",
    apiKey: "fr",
    bcp47: "fr-CA",
  },
  GERMAN_GERMANY: {
    nameTKey: "str_language_german",
    originalName: "Deutsch",
    flagName: "German",
    apiKey: "de",
    bcp47: "de-DE",
  },
  GREEK_GREECE: {
    nameTKey: "str_language_greek",
    originalName: "Ελληνικά",
    flagName: "Greek",
    apiKey: "el",
    bcp47: "el-GR",
  },
  GUJARATI_INDIA: {
    nameTKey: "str_language_gujarati",
    originalName: "Gujarati",
    flagName: "Gujarati",
    apiKey: "gu",
    bcp47: "gu-IN",
  },
  HINDI_INDIA: {
    nameTKey: "str_language_hindi",
    originalName: "हिन्दी",
    flagName: "Hindi",
    apiKey: "hi",
    bcp47: "hi-IN",
  },
  HUNGARIAN_HUNGARY: {
    nameTKey: "str_language_hungarian",
    originalName: "Magyar",
    flagName: "Hungarian",
    apiKey: "hu",
    bcp47: "hu-HU",
  },
  ICELANDIC_ICELAND: {
    nameTKey: "str_language_icelandic",
    originalName: "Icelandic",
    flagName: "Icelandic",
    apiKey: "is",
    bcp47: "is-IS",
  },
  INDONESIAN_INDONESIA: {
    nameTKey: "str_language_indonesian",
    originalName: "Bahasa Indonesia",
    flagName: "Indonesian",
    apiKey: "id",
    bcp47: "id-ID",
  },
  ITALIAN_ITALY: {
    nameTKey: "str_language_italian",
    originalName: "Italiano",
    flagName: "Italian",
    apiKey: "it",
    bcp47: "it-IT",
  },
  JAPANESE_JAPAN: {
    nameTKey: "str_language_japanese",
    originalName: "日本語",
    flagName: "Japanese",
    apiKey: "ja",
    bcp47: "ja-JP",
  },
  KANNADA_INDIA: {
    nameTKey: "str_language_kannada",
    originalName: "Kannada",
    flagName: "Kannada",
    apiKey: "kn",
    bcp47: "kn-IN",
  },
  KOREAN_SOUTH_KOREA: {
    nameTKey: "str_language_korean",
    originalName: "한국어",
    flagName: "Korean",
    apiKey: "ko",
    bcp47: "ko-KR",
  },
  LATVIAN_LATVIA: {
    nameTKey: "str_language_latvian",
    originalName: "Latviešu",
    flagName: "Latvian",
    apiKey: "lv",
    bcp47: "lv-LV",
  },
  MALAY_MALAYSIA: {
    nameTKey: "str_language_malay",
    originalName: "Bahasa Melayu",
    flagName: "Malay",
    apiKey: "ms",
    bcp47: "ms-MY",
  },
  MALAYALAM_INDIA: {
    nameTKey: "str_language_malayalam",
    originalName: "Malayalam",
    flagName: "Malayalam",
    apiKey: "ml",
    bcp47: "ml-IN",
  },
  NORWEGIAN_BOKMAL_NORWAY: {
    nameTKey: "str_language_norwegian",
    originalName: "Norsk",
    flagName: "Norwegian",
    apiKey: "no",
    bcp47: "nb-NO",
  },
  POLISH_POLAND: {
    nameTKey: "str_language_polish",
    originalName: "Polski",
    flagName: "Polish",
    apiKey: "pl",
    bcp47: "pl-PL",
  },
  PORTUGUESE_BRAZIL: {
    nameTKey: "str_language_portuguese_br",
    originalName: "Português (Brazil)",
    flagName: "PortugueseBrazil",
    apiKey: "pt",
    bcp47: "pt-BR",
  },
  PORTUGUESE_PORTUGAL: {
    nameTKey: "str_language_portuguese_pt",
    originalName: "Português (Portugal)",
    flagName: "PortuguesePortugal",
    apiKey: "pt-pt",
    bcp47: "pt-PT",
  },
  PUNJABI_GURMUKHI_INDIA: {
    nameTKey: "str_language_punjabi",
    originalName: "Punjabi",
    flagName: "Punjabi",
    apiKey: "pa",
    bcp47: "pa-IN",
  },
  ROMANIAN_ROMANIA: {
    nameTKey: "str_language_romanian",
    originalName: "Română",
    flagName: "Romanian",
    apiKey: "ro",
    bcp47: "ro-RO",
  },
  RUSSIAN_RUSSIA: {
    nameTKey: "str_language_russian",
    originalName: "Русский",
    flagName: "Russian",
    apiKey: "ru",
    bcp47: "ru-RU",
  },
  SERBIAN_SERBIA: {
    nameTKey: "str_language_serbian",
    originalName: "Srpski",
    flagName: "Serbian",
    apiKey: "sr-Latn",
    bcp47: "sr-Latn-RS",
  },
  SLOVAK_SLOVAKIA: {
    nameTKey: "str_language_slovak",
    originalName: "Slovenský",
    flagName: "Slovak",
    apiKey: "sk",
    bcp47: "sk-SK",
  },
  SPANISH_SPAIN: {
    nameTKey: "str_language_spanish_es",
    originalName: "Español (España)",
    flagName: "SpanishSpain",
    apiKey: "es",
    bcp47: "es-ES",
  },
  SPANISH_UNITED_STATES: {
    nameTKey: "str_language_spanish_am",
    originalName: "Spanish (America)",
    flagName: "SpanishAmerica",
    apiKey: "es",
    bcp47: "es-US",
  },
  SWEDISH_SWEDEN: {
    nameTKey: "str_language_swedish",
    originalName: "Svenska",
    flagName: "Swedish",
    apiKey: "sv",
    bcp47: "sv-SE",
  },
  TAMIL_INDIA: {
    nameTKey: "str_language_tamil",
    originalName: "Tamil",
    flagName: "Tamil",
    apiKey: "ta",
    bcp47: "ta-IN",
  },
  THAI_THAILAND: {
    nameTKey: "str_language_thai",
    originalName: "Thai",
    flagName: "Thai",
    apiKey: "th",
    bcp47: "th-TH",
  },
  TURKISH_TURKEY: {
    nameTKey: "str_language_turkish",
    originalName: "Türkçe",
    flagName: "Turkish",
    apiKey: "tr",
    bcp47: "tr-TR",
  },
  UKRAINIAN_UKRAINE: {
    nameTKey: "str_language_ukrainian",
    originalName: "Українська",
    flagName: "Ukrainian",
    apiKey: "uk",
    bcp47: "uk-UA",
  },
  VIETNAMESE_VIETNAM: {
    nameTKey: "str_language_vietnamese",
    originalName: "Tiếng Việt",
    flagName: "Vietnamese",
    apiKey: "vi",
    bcp47: "vi-VN",
  },
} as const;

export const resources = {
  ar: { translation: { ...common_ar, ...ar } },
  bg: { translation: { ...common_bg, ...bg } },
  ca: { translation: { ...common_ca, ...ca } },
  cs: { translation: { ...common_cs, ...cs } },
  da: { translation: { ...common_da, ...da } },
  de: { translation: { ...common_de, ...de } },
  en: { translation: { ...common_en, ...en } },
  es: { translation: { ...common_es, ...es } },
  et: { translation: { ...common_et, ...et } },
  fi: { translation: { ...common_fi, ...fi } },
  fr: { translation: { ...common_fr, ...fr } },
  hr: { translation: { ...common_hr, ...hr } },
  hu: { translation: { ...common_hu, ...hu } },
  it: { translation: { ...common_it, ...it } },
  ja: { translation: { ...common_ja, ...ja } },
  lt: { translation: { ...common_lt, ...lt } },
  lv: { translation: { ...common_lv, ...lv } },
  nl: { translation: { ...common_nl, ...nl } },
  pl: { translation: { ...common_pl, ...pl } },
  pt: { translation: { ...common_pt, ...pt } },
  ro: { translation: { ...common_ro, ...ro } },
  ru: { translation: { ...common_ru, ...ru } },
  sk: { translation: { ...common_sk, ...sk } },
  sv: { translation: { ...common_sv, ...sv } },
  tr: { translation: { ...common_tr, ...tr } },
  uk: { translation: { ...common_uk, ...uk } },
  bn: { translation: { ...common_bn, ...bn } },
  el: { translation: { ...common_el, ...el } },
  he: { translation: { ...common_he, ...he } },
  hi: { translation: { ...common_hi, ...hi } },
  ko: { translation: { ...common_ko, ...ko } },
  sq: { translation: { ...common_sq, ...sq } },
  zh: { translation: { ...common_zhcn, ...zh } },
};

export type SupportedLanguageName = keyof typeof supportedLanguages;

export type FlagTranslationKeys = (typeof supportedLanguages)[keyof typeof supportedLanguages]["nameTKey"];

export type SupportedLanguagesBcp47 = (typeof supportedLanguages)[keyof typeof supportedLanguages]["bcp47"];

export type SupportedLanguageKeys = (typeof supportedLanguages)[keyof typeof supportedLanguages]["apiKey"];

export type SupportedLanguage = (typeof supportedLanguages)[keyof typeof supportedLanguages];

export type SupportedLanguageWithName = SupportedLanguage & { languageName: SupportedLanguageName };
